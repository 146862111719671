<template>
  <section class="auth-section">
    <div class="container mob-container text-center">
      <!--            <LogoSvg class="logo"/>-->
      <img src="/images/logo_h.svg" class="logo" alt="logo"/>
      <h1>{{ $t('signin') }}</h1>
      <InputGroup label="Email" type="email" v-model="email"/>
      <InputGroup :label="$t('password')" v-model="password" type="password" @enter="login"/>
      <div class="form-group">
        <button @click="login" class="btn-themed w-100">{{ $t('signin') }}</button>
      </div>
      <div class="error" v-if="error">{{ error }}</div>
<!--      <div class="mt-4 text-left">-->
<!--        <router-link class="text-muted small text-underlined" to="/register">{{ $t('registration') }}</router-link>-->
<!--      </div>-->
      <div class="mt-4 text-left text-muted small">
        {{ $t('forgot_password') }} <router-link class="text-themed" to="/password/reset">{{ $t('reset_password') }}</router-link>
      </div>
<!--      <div class="mt-5 text-left text-muted small offer-links">-->
<!--        <a class="muted small" href="/files/Оферта_ИП_SKILLLMS.docx">Договор-оферта</a>-->
<!--        <span class="mx-2">|</span>-->
<!--        <a class="muted small" href="/files/Политика_конфиденциальности_ИП_ENGGO.docx">Политика конфиденциальности</a>-->
<!--      </div>-->
    </div>
  </section>
</template>

<script>

import InputGroup from "@/views/parts/general/form/InputGroup"
// import LogoSvg from "@/assets/svg/logo.svg"
import AuthService from "@/services/auth.helper"
import { UsersService } from '@/services/api.service'
import '@/assets/sass/auth.scss'
import {mapState} from "vuex"
import { isElectron } from 'mobile-device-detect'
import {getLoginCredentials, setLoginCredentials} from "../../../services/auth.helper"

export default {
  components: {
    // LogoSvg,
    InputGroup
  },
  data() {
    return {
      email: "",
      password: "",
      error: ""
    }
  },
  computed: {
    ...mapState({
      nextRedirectUrl: state => state.call.nextRedirectUrl,
    })
  },
  mounted() {
    if(this.$route.query.tkn) {
      AuthService.login({
        expires_in: 43200*60,
        access_token: this.$route.query.tkn,
        refresh_token: this.$route.query.tkn,
      }, null)
      location.href = '/'
    }
    if(isElectron) {
      let credentials = getLoginCredentials()
      if(credentials) {
        this.email = credentials.email
        this.password = credentials.password
      }
    }
  },
  methods: {
    login() {
      if(isElectron) {
        setLoginCredentials({
            email: this.email,
            password: this.password
        })
      }
      UsersService.login(this.email, this.password).then(res => {
        let r = this.apiResponse(res)
        if(r.success) {
          let user = r.data
          if  (user.api_token) {
            AuthService.login({
              expires_in: 43200*60,
              token_expires_in: user.api_token_expires_at,
              access_token: user.api_token,
              refresh_token: user.api_token,
            }, user)
            this.$store.commit('saveUser', { user: user, vm: this } )
            this.$store.dispatch('syncTimeZone')
            this.$store.dispatch('loadDictionary')
            if(this.nextRedirectUrl) {
              let path = this.nextRedirectUrl
              this.$store.commit('setNextRedirectUrl', null)
              this.$router.push(path)
            } else {
              this.$router.push('/')
            }
          }
        } else {
          this.error = r.data
        }
      }).catch(err => {
        console.log(err)
        this.error = this.$t("login_or_pass_incorrect")
      })
    }
  },
  watch: {
    email() {
      this.error = ''
    },
    password() {
      this.error = ''
    }
  },
  metaInfo() {
    return {
      title: this.$t('authorization'),
    }
  },
}
</script>

<style lang="scss" scoped>
.offer-links {
  a {
    color: #7E828C !important;
  }
}
</style>


